/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, YouTube, Image } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1tat072 --style3 --full" anim={null} name={"wstęp"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1400}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Grupa muzyczna</span>"}>
              </Subtitle>

              <Title className="title-box fs--154 lh--1 mt--10" style={{"maxWidth":""}} content={"<span style=\"color: white;\">The Strings</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--24 mt--16" content={"Najbliższe koncerty"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--60" name={"o-nas"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="pt--10" style={{"maxWidth":550}}>
              
              <Title className="title-box fs--62" content={"The Strings Zespół jazzowy z Krakowa"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pt--10" style={{"maxWidth":800}}>
              
              <YouTube embedId={""}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"o-nas-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--4 pb--10" anim={"2"} animS={"5"} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/138/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Andrzej Płowiecki"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Piotr Wieczorkowski"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/138/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Piotr Nowak"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Alicja Wrona"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1tat072 pb--60 pt--60" name={"nasza-twórczość"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":500}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Posłuchaj nas</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Nasza twórczość"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" anim={""} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="pt--10" style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box w--900" content={"<span style=\"color: var(--color-dominant);\">The Strings</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right pt--10" style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box w--900" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">+48 797 811 2X0<br>info@twoje-stronyy.com<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}